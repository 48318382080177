/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import PostSection from '../components/PostSection'

// import PostSection from '../components/PostSection'
import Content from '../components/Content'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
const HomePageTemplate = ({ data }) => {
  const { title, subTitle, featuredImage, body } = data.directus.homePage
  const { events: posts } = data.directus
  let pinnedEvents = []

  pinnedEvents = posts.filter((i) => {
    return i.pinned
  })

  pinnedEvents.sort((a, b) => {
    return new Date(a.eventDate) - new Date(b.eventDate)
  })

  const filteredPosts = posts.filter((i) => {
    const now = new Date()
    const rightNow = new Date()
    rightNow.setUTCHours(0, 0, 0, 0)
    now.setDate(now.getDate() + 7)
    const dt = Date.parse(i.eventDate)
    return dt < now && dt >= rightNow
  })
  filteredPosts.sort((a, b) => {
    return new Date(a.eventDate) - new Date(b.eventDate)
  })
  // let filteredPosts = []
  // if (posts.edges && posts.edges.length) {
  //   filteredPosts = posts.edges.filter((i) => {
  //     const now = new Date()
  //     const rightNow = new Date()
  //     rightNow.setUTCHours(0, 0, 0, 0)
  //     now.setDate(now.getDate() + 7)
  //     const dt = Date.parse(i.node.frontmatter.date)
  //     return dt < now && dt >= rightNow
  //   })
  //   filteredPosts = filteredPosts.map((i) => ({
  //     ...i.node,
  //     ...i.node.frontmatter,
  //     slug: i.node.fields.slug
  //   }))
  //   filteredPosts.sort((a, b) => {
  //     return new Date(a.frontmatter.date) - new Date(b.frontmatter.date)
  //   })
  // }
  return (
    <main className="Home">
      <PageHeader
        large
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />

      <section className="section">
        <div className="container">
          <Content body={body} />
        </div>
        <div className="container">
          {pinnedEvents.length > 0 && (
            <div>
              <section className="section">
                <div className="container">
                  <h2>Pinned Events</h2>
                  <PostSection
                    posts={pinnedEvents}
                    extraPath="/events"
                    returnPath="/"
                  />
                </div>
              </section>
            </div>
          )}
          {filteredPosts.length > 0 && (
            <div>
              <section className="section">
                <div className="container">
                  <h2>Latest News</h2>
                  <PostSection
                    posts={filteredPosts}
                    extraPath="/events"
                    returnPath="/"
                  />
                </div>
              </section>
            </div>
          )}
        </div>
      </section>
    </main>
  )
}

// Export Default HomePage for front-end
const HomePage = ({ data }) => {
  return (
    <Layout meta={data.directus.homePage.SEO || false}>
      <HomePageTemplate data={data} />
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage {
    directus {
      homePage {
        title
        subTitle
        featuredImage {
          id
        }
        body
        SEO {
          image {
            id
          }
          title
          description
        }
      }
      events {
        pinned
        SEO {
          image {
            id
          }
          title
          description
        }
        shortDescription
        title
        featuredImage {
          id
        }
        body
        eventDate
        slug
      }
    }
  }
`
